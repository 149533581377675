.about-container{
    display: flex;
    justify-content: center;
    overflow: hidden;

    .about-box{
        background-color: black;
        padding: 30px;
        display: grid;
        grid-template-columns: 300px 400px;
        margin: 30px;
        grid-gap: 30px;

        h3{
            align-self: center;
        }

        .image-container{
            display: flex;
            flex-direction: column;
            align-items: center;

            p{
                margin-top: 10px;
            }
        }

        .img-box{
            width: 100%;
            height: 300px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            align-self: center;
        }
    }
}