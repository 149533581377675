.footer-container{
  background-color: black;
  color: #8d8d8d;
  padding: 30px;
  display: flex;
  justify-content: center;

  i{
    font-size: 30px;
    margin: 5px;
    align-self: flex-end;
  }

  a{
    color: inherit;
  }

  .content{
    width: 1200px;
    display: grid;
    grid-template-columns: auto auto auto;

    .footer-item{
      display: flex;
      flex-direction: column;
    }

    .footer-item:not(:last-child){
      border-right: $border-style;
      margin-right: 20px;
    }

    .social-icons{
      flex-direction: row;
      justify-self: center;
    }
  }
}