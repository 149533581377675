.calendar-container{
  display: flex;
  flex-direction: column;

  .info{
    color: #00B6F0;

    i{
      font-size: 25px;
    }
  }

  .item{
    margin-bottom: 20px;

    .group-container{
      margin-bottom: 10px;
      background-color: #0e0e0e;
      border: $border-style;
      padding: 10px;
      border-radius: 10px;
      margin-right: 10px;


      .group-name{
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      }

      .month-container{
        margin-left: 20px;
        padding-left: 10px;
        margin-bottom: 30px;
        border-left: 2px solid #00B6F0;

        .month{
          border-bottom: $border-style;
          margin-bottom: 5px;
          font-weight: 700;
        }

        .day-item{
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          font-weight: 300;
        }

        .finished{
          color: #8d8d8d;
          text-decoration: line-through;
        }

        i{
          color: #00B6F0;
          padding-right: 5px;
        }
      }
    }
  }
}