.question-container{
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  background-color: black;
  margin: 10px auto;

  .question{
    font-size: 30px;
    font-weight: 700;
  }

  .answer{
    padding-left: 30px;

    li{
      margin: 0;
    }
  }
}