.full-screen-box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .floating-box {
    background-color: #060606;
    max-width: 600px;
    max-height: 700px;
    border-radius: 20px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 1);
    overflow: hidden;

    .header {
      background-color: #0e0e0e;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        font-size: 20px;
        cursor: pointer;
        color: #8d8d8d;
      }
    }

    .content{
      padding: 20px;
    }
  }
}