.bg-logo-unreal{
  width: 100%;
  position: fixed;
  z-index: -1;
  opacity: 0.02;
}

.course-cards-layout{
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;

  a{
    color: inherit;
  }

  .course-card{
    width: 100%;
    height: 500px;
    background-color: black;
    border-radius: 10px;
    padding: 20px;

    display: grid;
    grid-template-columns: 300px auto;
    grid-gap: 20px;

    .card-description{
      align-self: center;
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}

.image-text-container{
  min-height: 600px;
  background-position: bottom;
  background-size: cover;
  padding: 50px 100px;
  display: flex;
  align-items: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;

  .bg-box{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(90deg, rgba(10, 10, 10, 0.93) 30%, rgba(0,0,0,0) 100%);
  }

  .photo-credit{
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: rgba(255, 255, 255, 0.3);

    a{
      color: rgba(255, 255, 255, 0.4);
    }
  }
}

.text-box-black{
    background-color: rgba(0, 0, 0, 0.9);
    padding: 30px;
    max-width: 800px;
    z-index: 1;
}

.center-text-box{
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.center-text{
  text-align: center;
}

.bold-title{
  font-size: 60px;
  font-weight: 700;
}

.left-border{
  border-left: 5px solid #00B6F0;
  padding-left: 30px;
}

.app-list-container{
  display: flex;

  .app-box{
    margin: 20px;
    background-color: #292929;
    width: 180px;
    height: 180px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.51);

    .logo-box{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    img{
      width: 110px;
      margin-bottom: 10px;
    }
  }
}