.video-text-container{
  position: relative;
  display: flex;
  overflow: hidden;
  min-height: 550px;
  align-items: center;

  .video-player{
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .text-box-black{
    margin-left: 100px;
  }
}

.course-about-title{
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img{
    width: 80px;
    margin-right: 10px;
  }
}

.floating-panel{
  position: fixed;
  top: 70px;
  right: 15px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .floating-button{
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    border-radius: $default-radius;
    margin-bottom: 10px;
    font-size: 25px;
    display: flex;
    align-items: center;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.51);
    cursor: pointer;

    .icon-conteiner{
      margin-right: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .gradient-circle{
        width: 40px;
        height: 40px;
        border: 2px solid #00B6F0;
        border-radius: 50%;
        position: absolute;
      }

      i{
        font-size: 30px;
        z-index: 1;
      }
    }
  }
}

.course-title-container{
  display: grid;
  grid-template-columns: 500px auto;
  grid-gap: 20px;
  overflow: hidden;

  img{
    width: 100%;
  }

  .text-box-black{
    max-width: none;
    display: flex;
    align-items: center;
  }
}

.course-details-layout{
  display: grid;
  grid-template-columns: auto 500px;
  grid-gap: 20px;
}

.mailing-list-layout{
  display: flex;
  flex-direction: column;
  max-width: 650px;
  margin: auto auto;

  .mailing-list-field{
    display: flex;
    margin-top: 10px;

    button{
      align-self: center;
      margin-left: 10px;
    }
  }
}