.navbar{
  background-color: black;
  height: 60px;
  position: fixed;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.51);
  display: flex;
  align-items: center;
  z-index: 10;

  .social-icons{
    margin-right: 10px;
  }

  i {
    font-size: 30px;
    padding-left: 10px;
  }

  .mobile-menu-button{
    font-size: 30px;
    justify-self: flex-end;
    display: none;
    color: #00B6F0;
    order: -1;
  }

  .mobile-menu-bg{
    position: fixed;
    left: 0;
    right: 5px;
    top: 60px;
    bottom: 0;

    .mobile-menu-container{
      background-color: black;
      padding: 20px;
      border-top: 3px solid #00B6F0;
      border-bottom: 3px solid #00B6F0;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .logo{
    width: 365px;
    position: relative;
    top: 13px;
    left: -10px;
  }

  a{
    text-decoration: none;
    color: inherit;

    &:hover{
      color: #00B6F0;
      transition: color 0.3s;
    }
  }

  .nav-menu-container{
    display: flex;
    height: 100%;
    align-items: center;

    border-left: 3px solid #00B6F0;

    .nav-menu-item{
      padding: 0 10px;
      font-weight: 300;
      height: 100%;
      display: flex;
      align-items: center;
      border-bottom: 3px solid transparent;
      font-size: 22px;
      position: relative;
      user-select: none;

      &:hover{
        .submenu-container{
          display: flex;
          flex-direction: column;
        }
      }

      .submenu-container{
        position: absolute;
        top: 57px;
        left: 0;
        background-color: black;
        padding: 10px;
        font-size: 16px;
        min-width: 200px;
        color: white;
        border-top: 3px solid #00B6F0;
        display: none;
        flex-direction: column;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.51);

        .category-item{
          color: #8d8d8d;
        }

        a{
          display: block;
          padding-left: 20px;
        }
      }

      i{
        margin-right: 5px;
      }

      &:hover{
        color: #00B6F0;
        transition: color 0.3s;
        border-bottom: 3px solid #00B6F0;
      }
    }
  }
}