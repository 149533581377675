/* Mobile Styles */
@media only screen and (max-width: 700px) {
  .App{
    overflow-x: hidden;
  }

  .course-title-container{
    display: flex;
    flex-direction: column;
  }

  .logo{
    width: 200px!important;
    top: 0!important;
    left: 17px!important;
  }

  h3{
    font-size: 16px;
  }

  .about-container{
    flex-direction: column;
    .about-box{
      display: flex;
      flex-direction: column;
      padding: 20px;
      .img-box{
        width: 200px;
        height: 200px;
      }
    }
  }

  .navbar{
    justify-content: space-between;
    padding: 0 10px;

    i{
      font-size: 23px;
    }
  }

  .mobile-menu-button{
    display: block!important;
  }

  .nav-menu-container{
    display: none!important;
  }

  .nav-menu-item{
    font-size: 20px!important;
    margin-bottom: 10px;

    .submenu-container{
      padding-left: 20px!important;
      margin-top: 5px!important;
      font-size: 16px;
      margin-bottom: 20px;
      display: flex!important;
      flex-direction: column!important;

      .category-item{
        margin-right: 10px!important;
        color: #8d8d8d;

        &:after{
          content: "\00BB";
        }
      }

      a{
        margin: 10px;
        font-size: 18px;
      }
    }
  }


  .image-text-container{
    padding: 5px!important;

    .text-box-black{
      padding: 5px!important;

      .left-border{
        padding-left: 10px!important;
      }
    }
  }

  .bold-title{
    font-size: 40px!important;
    text-align: center!important;
  }

  .center-text-box{
    padding: 5px!important;
    text-align: center!important;
  }

  .app-list-container{
    display: grid;
    grid-template-columns: 150px 150px;
    grid-gap: 10px;
    margin-top: 10px!important;

    .app-box{
      width: 100%!important;
      height: 150px!important;
      margin: 0!important;

      img{
        width: 80px!important;
      }
    }
  }

  .footer-container{
    flex-direction: column;
    padding: 10px!important;

    .content{
      display: flex!important;
      flex-direction: column!important;
      justify-content: center!important;
      align-items: center!important;
      width: 100% !important;

      .footer-item{
        margin: 10px 0!important;
        border: none!important;
        display: flex!important;
        align-items: center!important;
      }
    }
  }

  .course-cards-layout{
    display: flex;
    flex-direction: column;

    .course-card{
      display: flex;
      flex-direction: column;
      height: auto;

      img{
        width: 250px;
        align-self: center;
      }
    }
  }

  .mailing-list-field{
    flex-direction: column;
  }

  .calendar-container{
    padding: 0 0 0 10px !important;
  }

  .course-title-content{
    padding: 10px;
  }

  h1{
    font-size: 30px !important;
  }

  .subtitle-text{
    text-align: center;
  }

  .group-container{
    .group-name{
      flex-direction: column!important;
      .button{
        margin: 10px 0;
      }
    }
  }

  .registration-form{
    .contact-info-box{
      flex-direction: column!important;
    }

    .payment-options-container{
      width: 100%!important;
    }
  }

  .floating-button{
    font-size: 16px!important;
    border: 2px solid white!important;

    i{
      font-size: 20px!important;
    }
  }

  

  .video-text-container{
    .text-box-black{
      margin-left: 5px!important;
      margin-top: 70px;
      padding: 5px!important;
      margin-bottom: 70px!important;

      .left-border{
        padding-left: 5px!important;
      }
    }
  }

  .course-about-title{
    img{
      width: 50px!important;
    }
  }

  .course-details-layout{
    display: flex;
    flex-direction: column;
  }
}


/* Desktop Styles */
@media only screen and (min-width: 961px) {

}