.registration-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;

  .registration-form{
    background-color: #060606;
    padding: 20px;
    color: #b7b7b7;
    display: flex;
    flex-direction: column;
    max-width: 800px;
    border-radius: 20px;

    .payment-options-container{
      display: flex;
      width: 80%;
      justify-content: space-between;
      align-self: center;
      padding: 10px;

      .paymen-option{
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .thank-you{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    i{
      font-size: 30px;
    }
    .company-box-check{
      display: flex;
      cursor: pointer;
      margin-bottom: 10px;
    }

  .company-box{
    border: $border-style;
    padding: 10px;
    border-radius: 10px;

    input{
      font-size: 14px;
    }
  }

  .contact-info-box{
    display: flex;
    input:not(:last-child){
      margin-right: 20px;
    }
  }

  button{
    align-self: flex-end;
  }

  .button-layout{
    display: flex;
    border: 1px solid red;
    justify-content: flex-end;
  }

  .data-protection-agreement{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
}

.error{
  color: red;
  justify-self: center;
  align-self: center;
  margin: 10px 0;
}

.checkbox{
    border: $border-style;
    border-radius: $default-radius;
    padding: 3px;
    margin-right: 5px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00B6F0;
    font-size: 18px;
}

.radio-button{
    border: $border-style;
    border-radius: 50%;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

  .circle{
    background-color: #00B6F0;
    width: 70%;
    height: 70%;
    border-radius: 50%;
  }
}
